<template>
  <div>
    <!--begin::Nav-->
    <b-row class="mx-n2 gutter-b">
      <!--begin::Item-->
      <b-col cols="6" md="" class="px-2 mb-4 mb-md-0">
        <KTCardCategory variant="primary" :url="getStatUrl('In Progress')" :loading="statLoading" :value="statistics.in_proggress" title="In Progress"></KTCardCategory>
      </b-col>
      <!--end::Item-->
      <!--begin::Item-->
      <b-col cols="6" md="" class="px-2 mb-4 mb-md-0">
        <KTCardCategory :url="getStatUrl('Coordinator Review')" :loading="statLoading" :value="statistics.coordinator_review" title="Coordinator Review"></KTCardCategory>
      </b-col>
      <!--end::Item-->
      <!--begin::Item-->
      <b-col cols="6" md="" class="px-2 mb-4 mb-md-0">
        <KTCardCategory variant="danger" :url="getStatUrl('Application Incomplete')" :loading="statLoading" :value="statistics.applicant_correction" title="Application Incomplete"></KTCardCategory>
      </b-col>
      <!--end::Item-->
      <!--begin::Item-->
      <b-col cols="6" md="" class="px-2 mb-4 mb-md-0">
        <KTCardCategory variant="success" :url="getStatUrl('Board Review')" :loading="statLoading" :value="statistics.board_review" title="Board Review"></KTCardCategory>
      </b-col>
      <!--end::Item-->
      <!--begin::Item-->
      <b-col cols="6" md="" class="px-2">
        <KTCardCategory variant="info" :url="getStatUrl('Board Rejected')" :loading="statLoading" :value="statistics.board_reject" title="Board Rejected"></KTCardCategory>
      </b-col>
      <!--end::Item-->
      <!--begin::Item-->
      <b-col cols="6" md="" class="px-2">
        <KTCardCategory variant="dark" :url="getStatUrl('Board Accepted')" :loading="statLoading" :value="statistics.board_approved" title="Board Accepted"></KTCardCategory>
      </b-col>
      <!--end::Item-->
    </b-row>
    <!--end::Nav-->
    <b-row>
      <b-col md="6">
        <b-card class="card-custom card-stretch gutter-b" header-class="d-flex">
          <template v-slot:header>
            <div class="card-title d-flex">
              <h3 class="card-label">Recent Activities</h3>
            </div>
            <div class="card-toolbar d-flex">
              <router-link to="/notifications" v-slot="{ href, navigate }">
                <a :href="href" @click="navigate">All Activities</a>
              </router-link>
            </div>
          </template>
          <KTTimelineList :items="notifications.slice(0, 5)"></KTTimelineList>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="card-custom card-stretch gutter-b" header-class="d-flex">
          <template v-slot:header>
            <div class="card-title d-flex">
              <h3 class="card-label">Transactions</h3>
            </div>
            <div class="card-toolbar d-flex">
              <router-link to="/transactions" v-slot="{ href, navigate }">
                <a :href="href" @click="navigate">See All</a>
              </router-link>
            </div>
          </template>
          <b-table class="mb-0" :items="transactions" :fields="transactionsFields">
            <template v-slot:cell(date)="data">
              {{ get(data, 'item.attributes.paid_date', '-') | moment('LL') }}
            </template>

            <template v-slot:cell(status)="data">
              <span class="label font-weight-bold label-lg label-inline" :class="['label-light-' + transactionStatuses[data.item.attributes.status]]">
                {{ data.item.attributes.status }}
              </span>
            </template>
            <template v-slot:cell(total)="data">
              <div :class="['text-' + transactionStatuses[data.item.attributes.status]]">
                <span>{{ moneyDollarForamter(get(data, 'item.attributes.application.data.attributes.full_fee')) }}</span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTTimelineList from '@/components/generic/TimelineList';
import KTCardCategory from '@/components/generic/CardCategory';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'dashboard',
  components: {
    KTTimelineList,
    KTCardCategory,
  },
  data() {
    return {
      transactionsFields: [
        {
          key: 'date',
          thStyle: {
            width: '15%',
          },
          tdClass: 'text-muted align-middle',
        },

        {
          key: 'status',
          thStyle: {
            width: '15%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'total',
          class: 'text-right',
          thStyle: {
            width: '15%',
          },
          tdClass: 'text-primary font-weight-bold align-middle',
        },
      ],
      statLoading: false,
      statistics: {
        in_proggress: 0,
        coordinator_review: 0,
        applicant_correction: 0,
        board_review: 0,
        board_reject: 0,
        board_approved: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/items',
      transactions: 'transactions/items',
      stats: 'applications/stats',
    }),
  },
  async mounted() {
    this.statLoading = true;
    this.getTransactions();
    await this.getStats();
    this.handleStaticts();
    console.log('stats :>> ', this.stats);
    this.statLoading = false;
  },
  methods: {
    ...mapActions({
      getTransactions: 'transactions/index',
      getStats: 'applications/stats',
    }),
    handleStaticts() {
      for (const key in this.stats) {
        if (!key) continue;
        if (Object.hasOwnProperty.call(this.stats, key)) {
          const count = this.stats[key];
          if (key == 'Coordinator Review') {
            this.statistics.coordinator_review = count;
          } else if (key == 'Application Incomplete') {
            this.statistics.applicant_correction = count;
          } else if (key == 'Board Review') {
            this.statistics.board_review = count;
          } else if (key == 'Board Rejected') {
            this.statistics.board_reject = count;
          } else if (key == 'Board Accepted') {
            this.statistics.board_approved = count;
          } else if (key == 'In Progress') {
            this.statistics.in_proggress = count;
          }
        }
      }
    },
    getStatUrl(status) {
      return {
        name: 'applications',
        query: {
          status,
        },
      };
    },
    dayFromDate(date) {
      const fullDate = new Date(date);

      return fullDate.getDate();
    },
    monthFromDate(date) {
      const fullDate = new Date(date),
        months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      return months[fullDate.getMonth()].substring(0, 3);
    },
    numberWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>
