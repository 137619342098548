<template>
  <router-link :to="url" v-slot="{ href, navigate }">
    <a :class="`d-block h-130px h-lg-100 bg-light-${variant} px-6 py-8 rounded-xl`" :href="href" @click="navigate">
      <span :class="`svg-icon svg-icon-3x svg-icon-${variant} d-flex align-items-center`">
        <inline-svg :src="icon" />
        <span v-if="loading" v-cs-loading="{ loading, variant }"> </span>
        <span v-else :class="`font-weight-bold font-size-h1-xxl text-${variant} ml-3`">
          {{ value }}
        </span>
      </span>
      <span :class="`text-${variant} font-weight-bold font-size-h6`">
        {{ title }}
      </span>
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'KTCardCategory',
  props: {
    variant: {
      type: String,
      default: 'warning',
    },
    loading: Boolean,
    url: [String, Object],
    value: Number,
    title: String,
    icon: {
      type: String,
      default: 'media/svg/icons/Media/Equalizer.svg',
    },
  },
};
</script>
